import { Injectable } from '@angular/core';
import { LocalStorageService } from '@Services/local-storage/local-storage.service';
import { AuthToken, RefreshTokenResponse } from '@shared/interfaces/common.interface';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { mergeMap, Observable, of, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Service for managing tokens.
 */
export class TokenService {
  TOKEN_KEY = 'auth-token';

  /**
   * Constructs a new instance of the TokenService class.
   * @param localStorageService The service for interacting with local storage.
   * @param httpClient The HTTP client for making API calls.
   */
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly httpClient: HttpClient,
  ) {}

  /**
   * Removes the tokens from local storage.
   */
  removeAuthTokens(): void {
    this.localStorageService.clearItem(this.TOKEN_KEY);
  }

  /**
   * Saves the tokens to local storage.
   * @param token The token to be saved.
   */
  saveAuthTokens(token: string, refreshToken: string): void {
    this.localStorageService.setItem<AuthToken>(this.TOKEN_KEY, { token, refreshToken });
  }

  /**
   * Retrieves the token from local storage.
   * @returns The token if it exists, otherwise null.
   */
  getToken(): string | null {
    return this.localStorageService.getItem<AuthToken>(this.TOKEN_KEY)?.token ?? null;
  }

  /**
   * Retrieves the refresh token from local storage.
   * @returns The refresh token if it exists, otherwise null.
   */
  getRefreshToken(): string | null {
    return this.localStorageService.getItem<AuthToken>(this.TOKEN_KEY)?.refreshToken ?? null;
  }

  /**
   * Refreshes the authentication token.
   * @returns An observable of the new token.
   */
  refreshAuthToken(): Observable<AuthToken> {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      return throwError(
        () =>
          new HttpErrorResponse({
            status: HttpStatusCode.Unauthorized,
            statusText: 'Please login to continue using REi platform',
          }),
      );
    }

    const refreshTokenUrl = 'token/refresh/';

    return this.httpClient
      .post<RefreshTokenResponse>(refreshTokenUrl, { refresh: refreshToken })
      .pipe(
        // save the new token
        tap((response: RefreshTokenResponse) => {
          if (!response.access || !response.refresh) {
            throw new HttpErrorResponse({
              status: HttpStatusCode.Unauthorized,
              statusText: 'Please login to continue using REi platform',
            });
          }
          this.saveAuthTokens(response.access, response.refresh);
        }),
        // return the new token in AuthToken format
        mergeMap((response: RefreshTokenResponse) => {
          return of({
            token: response.access,
            refreshToken: response.refresh,
          } as AuthToken);
        }),
      );
  }

  /**
   * Checks if the user is authenticated.
   * @returns True if the user is authenticated, false otherwise.
   */
  isAuthenticated(): boolean {
    return !!this.getToken();
  }
}
